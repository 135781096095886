import React from 'react';
import PropTypes from 'prop-types';

const FormButton = ({ label, onClick = null, href = null }) => {
  const style =
    'py-2 px-5 min-h-12.5 justify-center bg-altGreen hover:bg-altGreen-400 active:bg-altGreen-600 rounded transition-colors duration-200 uppercase sm:w-auto w-full flex items-center font-bold text-white md:text-base text-sm';

  return href ? (
    <a href={href} className={style} target="_blank" rel="noreferrer noopener">
      {label}
    </a>
  ) : (
    <button className={style} onClick={onClick}>
      {label}
    </button>
  );
};

FormButton.propTypes = {
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

export default FormButton;
