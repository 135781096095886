import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { mapAnswers } from '@/utils/helpers';
import AppHeader from '@/components/AppHeader';
import QuoteForm from '@/components/QuoteForm';
import YourQuotes from '@/components/YourQuotes';
import CompareRatesFrom from '@/components/CompareRatesFrom';
import AppFooter from '@/components/AppFooter';
import Alert from '@/components/Alert';
import axios from '@/config/axios';

const HomePage = () => {
  const [showQuotes, setShowQuotes] = useState(false);
  const { questions, answers } = useSelector((store) => store.form);
  const location = useLocation();
  const [offerId, setOfferId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingRates, setLoadingRates] = useState(true);
  const [unableShowQuotes, setUnableShowQuotes] = useState(false);
  const [quotes, setQuotes] = useState([]);

  const submitForm = async () => {
    setShowQuotes(true);

    // const params = {
    //   offer_id: offerId,
    //   payload: mapAnswers(),
    // };

    // try {
    //   const { data } = await axios().post('survey/submit', params);
    //   setQuotes(data.result);
    //   setLoadingRates(false);
    // } catch (err) {
    //   setUnableShowQuotes(true);
    // }
  };

  const getOffer = () => {
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    setOfferId(query.get('offer_id'));
    setLoading(false);
  };

  const errorMsg = `${
    unableShowQuotes
      ? 'We were unable to load your rates'
      : 'Something went wrong'
  }. Please reload the page or try again later.`;

  useEffect(() => {
    getOffer();
  }, []);

  return (
    <>
      <Helmet>
        <title>Wayless Quotes</title>
      </Helmet>

      <div>
        <AppHeader />

        {(!offerId && !loading) || unableShowQuotes ? (
          <div className="m-8">
            <Alert>{errorMsg}</Alert>
          </div>
        ) : showQuotes ? (
          <YourQuotes quotes={quotes} loading={loadingRates} />
        ) : (
          <>
            <QuoteForm onShowQuotes={submitForm} />
            <CompareRatesFrom />
          </>
        )}

        <AppFooter />
      </div>
    </>
  );
};

export default HomePage;
