import React from 'react';
import Logo from '@/components/Logo';

const AppFooter = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="p-4 md:p-8 flex flex-col items-center mb-4">
      <section className="text-electricBlue">
        <Logo />
      </section>
      <section className="text-gray-800">
        <div className="py-5 sm:py-8 text-center">
          <small className="text-sm">
            &copy; {year} waylessquotes.com. All rights reserved.
          </small>
        </div>
        <nav className="flex items-center justify-center">
          <a
            href="mailto:sales@admobo.com"
            className="text-xs sm:text-base m-1 md:mx-3 font-medium hover:text-gray-700 transition-colors duration-200"
          >
            Contact Us
          </a>
          <a
            href="http://login.admobo.com/signup_affiliate/8"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs sm:text-base m-1 md:mx-3 font-medium hover:text-gray-700 transition-colors duration-200"
          >
            Affiliates
          </a>
          <a
            href="https://www.waylessquotes.com/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs sm:text-base m-1 md:mx-3 font-medium hover:text-gray-700 transition-colors duration-200"
          >
            Privacy Policy
          </a>
        </nav>
      </section>
    </footer>
  );
};

export default AppFooter;
