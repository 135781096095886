import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  setAnswer,
  goToNextQuestion,
  goToPreviousQuestion,
  addError,
} from '@/store/slices/formSlice';
import ProgressBar from '@/components/ProgressBar';
import PreviousQuestion from '@/components/PreviousQuestion';
// import LoadingSkeleton from '@/components/LoadingSkeleton';
import FormButton from '@/components/FormButton';
import QuoteSelect from './components/QuoteSelect';
import QuoteLevelSelect from './components/QuoteLevelSelect';
import QuoteFormItem from './components/QuoteFormItem';
import QuoteAddress from './components/QuoteAddress';

const QuoteForm = ({ onShowQuotes }) => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const { questions, currentQuestion, answers, total } = useSelector(
    (store) => store.form
  );

  const {
    label,
    itemLabel,
    formType,
    options,
    otherOptions,
    otherOptionsLabel,
    autoNext,
    placeholder,
    btnLabel,
    name,
    validate,
  } = questions[`q${currentQuestion}`];

  const handleOnChange = (answer, subId) => {
    const answerUpdate = {
      qId: currentQuestion,
    };

    if (subId) {
      answerUpdate.answer = { ...answers[currentQuestion] } || {};
      answerUpdate.answer[subId] = answer;
    } else {
      answerUpdate.answer = answer;
    }

    dispatch(setAnswer(answerUpdate));

    if (autoNext) {
      nextQuestion();
    }
  };

  const nextQuestion = () => {
    if (total === currentQuestion) {
      onShowQuotes();
      return;
    }
    // setLoading(true);
    setFadeOut(true);
    setFadeIn(false);
    setTimeout(() => {
      dispatch(goToNextQuestion(currentQuestion));
    }, 100);
  };

  const previousQuestions = () => {
    setFadeOut(true);
    setFadeIn(false);
    setTimeout(() => {
      dispatch(goToPreviousQuestion(currentQuestion));
    }, 100);
  };

  const validateForm = () => {
    const regx = {
      birthdate: /^(19|20)\d\d+[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      email: /^.+@[a-zA-Z_]+?\.[a-zA-Z]{2,10}$/,
      phone: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
    };
    // General validation
    if (validate) {
      const errorName = name || 'This field';

      // Required
      if (validate.required && !answers[currentQuestion]) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: `${errorName} is required`,
          })
        );
        return false;
      }

      // Length
      if (
        validate.length &&
        answers[currentQuestion].length !== validate.length
      ) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: `${errorName} is incorrect`,
          })
        );
        return false;
      }

      if (
        validate.regex &&
        answers[currentQuestion] &&
        !regx[validate.regex].test(answers[currentQuestion])
      ) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: `${errorName} is incorrect`,
          })
        );
        return false;
      }
    }

    // Subform validation
    if (formType === 'level-select') {
      for (const option of options) {
        if (
          option.validate.required &&
          (!answers[currentQuestion] || !answers[currentQuestion][option.id])
        ) {
          dispatch(
            addError({
              qId: currentQuestion,
              subId: option.id,
              error: 'This field is required',
            })
          );
          return false;
        }
        if (
          option.validate.regex &&
          answers[currentQuestion][option.id] &&
          !regx[option.validate.regex].test(answers[currentQuestion][option.id])
        ) {
          dispatch(
            addError({
              qId: currentQuestion,
              subId: option.id,
              error: `This field is incorrect`,
            })
          );
          return false;
        }
      }
    }

    return true;
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      nextQuestion();
    }
  };

  const showLabel = () => {
    if (!label) return;

    return label;
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !autoNext) {
      handleSubmit();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
  }, [currentQuestion]);

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        setFadeIn(true);
        setFadeOut(false);
      }, 500);
    }
  }, [fadeOut]);

  return (
    <section
      onKeyDown={handleKeyDown}
      role="presentation"
      className="bg-white max-w-5xl p-5 md:p-8 mx-auto md:mb-0 mb-8"
    >
      <div className="mb-4">
        <ProgressBar />
      </div>

      {/* {loading ? (
        <LoadingSkeleton />
      ) : ( */}
      <div
        className={`${fadeIn ? 'opacity-100' : ''} ${
          fadeOut ? 'opacity-0' : ''
        } opacity-0 transition-all duration-100`}
      >
        <PreviousQuestion onClick={previousQuestions} />
        <div className="my-4 sm:mt-10 sm:mb-7 text-lg">{showLabel()}</div>

        {formType === 'select' && (
          <QuoteSelect
            options={options}
            otherOptions={otherOptions}
            otherOptionsLabel={otherOptionsLabel}
            autoNext={autoNext}
            onSelect={handleOnChange}
          />
        )}

        {formType === 'level-select' && (
          <QuoteLevelSelect options={options} onSelect={handleOnChange} />
        )}

        {formType === 'address' && <QuoteAddress onSelect={handleOnChange} />}

        {[
          'birthday',
          'email',
          'text',
          'post-code',
          'zip-code',
          'phone',
        ].includes(formType) && (
          <QuoteFormItem
            label={itemLabel}
            formType={formType}
            onChange={handleOnChange}
            placeholder={placeholder}
          />
        )}

        {!autoNext && (
          <section className="flex justify-start mt-6">
            <FormButton onClick={handleSubmit} label={btnLabel} />
          </section>
        )}
      </div>
      {/* )} */}
    </section>
  );
};

QuoteForm.propTypes = {
  onShowQuotes: PropTypes.func.isRequired,
};

export default QuoteForm;
