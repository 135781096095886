import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeError } from '@/store/slices/formSlice';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const QuoteFormItem = ({
  formType,
  name,
  placeholder = '',
  onChange,
  label = '',
  subId = null,
}) => {
  const type = ['birthday', 'phone', 'zip-code'].includes(formType)
    ? 'tel'
    : 'text';
  const dispatch = useDispatch();
  const hasMask = ['birthday', 'phone', 'post-code', 'zip-code'].includes(
    formType
  );

  const { currentQuestion, answers, errors } = useSelector(
    (store) => store.form
  );
  const getDefault =
    subId && answers[currentQuestion]
      ? answers[currentQuestion][subId]
      : answers[currentQuestion];

  const [defaultValue, setDefaultValue] = useState(getDefault);

  const error =
    subId && errors[currentQuestion]
      ? errors[currentQuestion][subId]
      : errors[currentQuestion];

  const inputStyle = `${
    error ? 'border-red bg-red-100' : 'border-gray bg-gray-200'
  } border rounded  p-3 sm:min-w-xxs  sm:max-w-xxs transition-colors text-blue focus:border-blue duration-200`;

  const setMask = () => {
    if (formType === 'post-code') {
      const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
      const letter = /(?!.*[DFIOQU])[A-Z]/i;
      const digit = /[0-9]/;
      return [firstLetter, digit, letter, ' ', digit, letter, digit];
    }

    if (formType === 'zip-code') {
      return '99999';
    }

    if (formType === 'birthday') {
      return '9999/99/99';
    }

    if (formType === 'phone') {
      return '(999) 999-9999';
    }
  };

  const handleOnChange = (e) => {
    const value = e.target.value;

    if (value && error) {
      dispatch(removeError({ qId: currentQuestion }));
    }
    onChange(value);
  };

  return (
    <div className="mb-4 flex flex-col">
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          className="text-sm text-gray-800 mb-2"
          dangerouslySetInnerHTML={{ __html: label }}
          htmlFor={formType}
        />
      )}

      {hasMask ? (
        <InputMask
          type={type}
          name={name}
          mask={setMask()}
          placeholder={placeholder}
          onChange={handleOnChange}
          defaultValue={defaultValue}
          maskPlaceholder={null}
          className={inputStyle}
        />
      ) : (
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleOnChange}
          className={inputStyle}
          value={defaultValue}
        />
      )}
      {error && <div className="mt-1 text-red text-sm">{error}</div>}
    </div>
  );
};

QuoteFormItem.propTypes = {
  formType: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  subId: PropTypes.number,
};

export default QuoteFormItem;
