import React from 'react';
import PropTypes from 'prop-types';
import { usePlacesWidget } from 'react-google-autocomplete';
import QuoteFormItem from './QuoteFormItem';

const QuoteAddress = ({ onChange }) => {
  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      console.log(place);
    },
  });

  return (
    <div>
      <input
        ref={ref}
        type="text"
        placeholder=""
        onChange={(e) => onChange(e.target.value)}
        className={inputStyle}
      />
    </div>
  );
};

QuoteAddress.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default QuoteAddress;
