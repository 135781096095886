export const makes = [
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ford',
  'Honda',
  'Hyundai',
  'Jeep',
  'Kia',
  'Nissan',
  'Toyota',
];

export const otherMakes = [
  'Audi',
  'BMW',
  'Buick',
  'Cadillac',
  'Fiat',
  'GMC',
  'Lincoln',
  'Mazda',
  'Lexus',
  'Porsche',
  'Suzuki',
  'Volkswagen',
  'Volvo',
];
