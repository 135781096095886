import React from 'react';
import { css } from '@emotion/css';
import esurance from '@/assets/img/esurance.png';
import farmersInsurance from '@/assets/img/farmers-insurance.png';
import liberty from '@/assets/img/liberty.png';
import nationwide from '@/assets/img/nationwide.png';
import progressive from '@/assets/img/progressive.png';
import statefarm from '@/assets/img/statefarm.png';
import travelers from '@/assets/img/travelers.png';
import usaa from '@/assets/img/usaa.png';

const CompareRatesFrom = () => {
  const companies = [
    {
      label: 'Nationwide',
      logo: nationwide,
      url: '',
    },
    {
      label: 'Farmer Insurance',
      logo: farmersInsurance,
      url: '',
    },
    {
      label: 'esurance',
      logo: esurance,
      url: '',
    },
    {
      label: 'progressive',
      logo: progressive,
      url: '',
    },
    {
      label: 'travelers',
      logo: travelers,
      url: '',
    },
    {
      label: 'statefarm',
      logo: statefarm,
      url: '',
    },
    {
      label: 'usaa',
      logo: usaa,
      url: '',
    },
    {
      label: 'liberty',
      logo: liberty,
      url: '',
    },
  ];

  const linkStyle = css`
    div {
      img {
        filter: grayscale(1);
        opacity: 0.7;
      }
      &:hover {
        img {
          opacity: 1;
          filter: grayscale(0);
        }
      }
    }
  `;

  return (
    <section className="sm:block hidden p-8 mt-8 border-t border-b border-gray-200">
      <h3 className="text-center font-bold text-electricBlue-600">
        Compare Rates From Insurance Companies Like
      </h3>
      <section
        className={`${linkStyle} mt-8 mb-4 grid grid-cols-2 md:grid-cols-4 gap-10 max-w-2xl mx-auto`}
      >
        {companies.map(({ label, logo, url }, i) => (
          <div
            key={`lk-${i}`}
            className="h-10 sm:h-12 md:h-14 flex items-center justify-center "
          >
            <img
              src={logo}
              alt={`${label} logo`}
              className="w-auto max-h-12 md:max-h-14 transition-all duration-200"
            />
          </div>
        ))}
      </section>
    </section>
  );
};

export default CompareRatesFrom;
