import React from 'react';
import Logo from '@/components/Logo';

const AppHeader = () => (
  <header className="bg-electricBlue p-2">
    <section className="text-white md:h-13.5 max-w-5xl mx-auto flex md:flex-row flex-col items-center justify-start">
      <Logo />
      <div className="text-sm md:flex hidden ml-4 pl-3  text-left  border-l border-electricBlue-400 h-8  items-center">
        Compare insurance quotes from top insurance providers with one simple
        form!
      </div>
    </section>
  </header>
);

export default AppHeader;
