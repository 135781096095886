export default {
  Audi: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8'],
  BMW: [
    'I3',
    'I8',
    'M3',
    'M4',
    'M5',
    'M6',
    'Serie 1',
    'Serie 2',
    'Serie 3',
    'Serie 4',
    'X1',
    'X2',
    'X3',
  ],
  Buick: ['Enclave', 'Encore', 'Lacrosse', 'Regal', 'Verano', 'Wildcat'],
  Cadillac: ['ATS', 'CTS', 'ELR', 'Escalade', 'Escalade ESV', 'SRX', 'XTS'],
  Chevrolet: ['Beretta', 'Caprice', 'Citation', 'Z 28'],
  Chrysler: [
    'Crossfire',
    'Es',
    'Grand Voyager',
    'Le Baron',
    'Neon',
    'New Yorker',
    'Pt Cruiser',
    'Viper',
    'Vision',
    'Voyager',
  ],
  Dodge: ['Avenger', 'Caliber', 'Journey', 'Nitro', 'Viper'],
  Fiat: ['500', '500L', 'Courrier', 'Panda', 'Punto', 'Stilo'],
  Ford: [
    'Aerostar',
    'B-max',
    'C-max',
    'Cougar',
    'Courrier',
    'Edge',
    'Escort',
    'Explorer',
    'Fiesta',
    'Focus',
    'Fusion',
    'Galaxy',
    'Grand C-max',
    'Kuga',
    'Mondeo',
    'Mustang',
  ],
  GMC: [
    'Acadia',
    'Savana 1500',
    'Savana 2500',
    'Savana 3500',
    'Savana 4500',
    'Sierra 1500',
    'Sierra 2500 HD',
    'Sierra 3500 HD',
    'Terrain',
    'Yukon',
  ],
  Honda: [
    'Accord',
    'CRX',
    'Civic',
    'Honda e',
    'Insight',
    'Integra',
    'Jazz',
    'Nsx',
    'Prelude',
    'S2000',
  ],
  Hyundai: [
    'Accent',
    'Elantra',
    'Genesis',
    'Grandeur',
    'I10',
    'I20',
    'I30',
    'Santa Fe',
    'Sonata',
  ],
  Jeep: ['Cherokee', 'Commander', 'Patriot', 'Renegade', 'Wrangler'],
  Kia: [
    'Carens',
    'Clarus',
    'Leo',
    'Magentis',
    'Niro',
    'Optima',
    'Picanto',
    'Pride',
    'Rio',
    'Sportage',
  ],
  Lexus: [
    'CT200H',
    'ES300H',
    'ES350',
    'GS350',
    'GS450H',
    'GX460',
    'IS F',
    'IS250',
    'IS350',
    'LS460',
    'LS600H',
    'LX570',
    'RX350',
    'RX450H',
  ],
  Lincoln: ['Mark LT', 'MKS', 'MKT', 'MKX', 'MKZ', 'Navigator'],
  Mazda: ['6', 'CX-5', 'CX-7', 'CX-9', 'RX-8', 'Tribute'],
  Nissan: [
    '370z',
    'Cherry',
    'Cube',
    'Juke',
    'Leaf',
    'Micra',
    'Patrol',
    'Primera',
    'Pulsar',
    'Qashquai',
    'Terrano',
  ],
  Porsche: ['911', 'Boxter', 'Cayenne', 'Panamera'],
  Suzuki: [
    'DR-Z125',
    'DR-Z400S',
    'DR-Z400SM',
    'GSX-R1000',
    'GSX-R1000 SE',
    'GSX-R600',
    'GSX-R750',
  ],
  Toyota: [
    'Auris',
    'Avensis',
    'Corolla',
    'Land Cruiser',
    'Lexus',
    'Model',
    'Prius',
    'Rav 4',
    'Yaris',
  ],
  Volkswagen: [
    'Beetle',
    'Cabrio',
    'Gold',
    'Jetta',
    'Passat',
    'Sedan',
    'Tiguan',
  ],
  Volvo: ['C70', 'S40', 'S60', 'V60', 'XC60', 'XC70'],
};
