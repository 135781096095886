import React from 'react';
import PropTypes from 'prop-types';
import FormButton from '@/components/FormButton';

const Rate = ({ rate }) => (
  <section
    style={{ background: rate.recommended ? '#fdfaf5' : '#fff' }}
    className="rate sm:min-h-30 relative overflow-hidden flex justify-between items-center py-6 sm:px-8 pl-8 pr-6 border border-gray-300"
  >
    {rate.recommended && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 76 76"
        className="absolute top-0 left-0 w-10 sm:w-12"
      >
        <path
          fill="#EE8434"
          d="M76,0 L0,76 L0,0 L76,0 Z M22.5,7 L17.9446643,16.2301183 L7.758624,17.7102366 L15.129312,24.8948817 L13.3893286,35.0397634 L22.5,30.25 L31.6106714,35.0397634 L29.870688,24.8948817 L37.241376,17.7102366 L27.0553357,16.2301183 L22.5,7 Z"
        />
      </svg>
    )}
    <img
      src={rate.logo}
      alt={`${rate.label} logo`}
      className="w-16 sm:w-auto max-w-28 max-h-16"
    />
    <div className="max-w-xxs">
      <FormButton href={rate.url} label="View My Rate" />
    </div>
  </section>
);

Rate.propTypes = {
  rate: PropTypes.object.isRequired,
};

export default Rate;
