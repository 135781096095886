import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QuoteSelect from './QuoteSelect';
import QuoteFormItem from './QuoteFormItem';

const QuoteLevelSelect = ({ options, onSelect, errors }) => {
  useEffect(() => {
    console.log('qls mount!', options);
  }, []);

  return (
    <div className="flex flex-wrap max-w-xl">
      {options.map(
        (
          { formType, itemLabel, options, label, name, placeholder = '', id },
          i
        ) => {
          const isFormItem = [
            'birthday',
            'email',
            'text',
            'post-code',
            'zip-code',
            'phone',
          ].includes(formType);

          return (
            <div
              key={`qls-${i}`}
              className={
                isFormItem ? 'sm:mr-4 sm:w-auto w-full' : 'w-full mb-5 sm:mb-8'
              }
            >
              {label && (
                <h3
                  className="mb-2 sm:mb-5"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              )}

              {formType === 'select' && (
                <QuoteSelect
                  options={options}
                  onSelect={(answer) => onSelect(answer, id)}
                  subId={id}
                />
              )}
              {isFormItem && (
                <QuoteFormItem
                  key={`t-${id}-${name}`}
                  name={name}
                  label={itemLabel}
                  formType={formType}
                  onChange={(answer) => onSelect(answer, id)}
                  placeholder={placeholder}
                  subId={id}
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

QuoteLevelSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default QuoteLevelSelect;
