import PropTypes from 'prop-types';

const Alert = ({ children }) => {
  return <div className="rounded p-3 bg-red-500 text-white">{children}</div>;
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Alert;
