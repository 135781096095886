import { Route, Switch } from 'react-router-dom';

import DefaultLayout from '@/layout/DefaultLayout';
import HomePage from '@/pages/index';

const renderRoutes = () => {
  const CurrentLayout = DefaultLayout;

  return (
    <CurrentLayout>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
      </Switch>
    </CurrentLayout>
  );
};

export default renderRoutes;
