import React from 'react';
import PropTypes from 'prop-types';
import { removeError } from '@/store/slices/formSlice';
import { useSelector, useDispatch } from 'react-redux';
import QuoteButton from './QuoteButton';
import QuoteFormSelect from './QuoteFormSelect';

const QuoteSelect = ({
  options,
  otherOptions = null,
  otherOptionsLabel = '',
  onSelect,
  subId = false,
}) => {
  const dispatch = useDispatch();
  const { currentQuestion, answers, errors } = useSelector(
    (store) => store.form
  );

  const isOptionActive = (option) =>
    subId && answers[currentQuestion]
      ? answers[currentQuestion][subId] === option
      : answers[currentQuestion] === option;

  const error =
    subId && errors[currentQuestion]
      ? errors[currentQuestion][subId]
      : errors[currentQuestion];

  const handleOnSelect = (option) => {
    if (option && error) {
      dispatch(removeError({ qId: currentQuestion, subId }));
    }
    onSelect(option);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4 sm:gap-0 sm:flex sm:flex-wrap sm:-m-2">
        {options.map((option, i) => (
          <div key={`opt-${i}`} className="sm:m-2">
            <QuoteButton
              label={option}
              onClick={() => handleOnSelect(option)}
              isActive={isOptionActive(option)}
            />
          </div>
        ))}
      </div>
      {otherOptions && (
        <div className="mt-4">
          <QuoteFormSelect
            options={otherOptions}
            otherOptionsLabel={otherOptionsLabel}
            onClick={handleOnSelect}
          />
        </div>
      )}
      {error && <div className="mt-1 text-red text-sm">{error}</div>}
    </>
  );
};

QuoteSelect.propTypes = {
  options: PropTypes.array.isRequired,
  otherOptions: PropTypes.array,
  otherOptionsLabel: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  subId: PropTypes.number,
};

export default QuoteSelect;
