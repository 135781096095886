export const getNextQuestion = (currentQuestion, questions, answers, total) => {
  let next = 1;

  for (let i = currentQuestion + 1; i <= total; i++) {
    const nextQ = questions[`q${i}`];
    if (nextQ && nextQ.skipIf) {
      const skipKey = Object.keys(nextQ.skipIf)[0];
      if (skipKey in answers && answers[skipKey] === nextQ.skipIf[skipKey]) {
        next++;
      }
    } else {
      break;
    }
  }
  return next;
};

export const getPreviousQuestion = (currentQuestion, questions, answers) => {
  let previous = 1;

  for (let i = currentQuestion - 1; i >= 1; i--) {
    const prevQ = questions[`q${i}`];

    if (prevQ && prevQ.skipIf) {
      const skipKey = Object.keys(prevQ.skipIf)[0];
      if (skipKey in answers && answers[skipKey] === prevQ.skipIf[skipKey]) {
        previous++;
      }
    } else {
      break;
    }
  }
  return previous;
};

export const mapAnswers = (questions, answers) => {
  const payload = {};
  for (const answerId of Object.keys(answers)) {
    const q = questions[`q${answerId}`];
    if (
      typeof answers[answerId] === 'object' &&
      questions[`q${answerId}`].options
    ) {
      let i = 1;
      for (const option of questions[`q${answerId}`].options) {
        payload[option.name] = answers[answerId][i];
        i++;
      }
    } else if (q.name) {
      payload[q.name] = answers[answerId];
    }
  }
  return payload;
};
