import React from 'react';
import PropTypes from 'prop-types';

const QuoteButton = ({ label, onClick, isActive = false }) => {
  return (
    <button
      className={`${
        isActive ? 'bg-blue   text-white' : 'text-blue  bg-gray-200'
      } px-4 w-full leading-none min-h-12.5 min-w-20 sm:min-w-28 rounded text-lg transition-colors duration-100 font-bold hoverable:hover:bg-blue-400 active:bg-blue-600 active:text-white hoverable:hover:text-white`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

QuoteButton.propTypes = {
  label: PropTypes.any.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default QuoteButton;
