import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { goToPreviousQuestion } from '@/store/slices/formSlice';
import { css } from '@emotion/css';

const PreviousQuestion = ({ onClick }) => {
  const dispatch = useDispatch();
  const { currentQuestion, total } = useSelector((store) => store.form);

  const showPrevious = currentQuestion > 1 && currentQuestion <= total;

  const btnStyles = css`
    &:hover svg {
      transform: translateX(-3px);
    }
  `;

  return (
    <div className={showPrevious ? 'h-6 mt-7' : 'mt-7'}>
      {showPrevious && (
        <button
          className={`${btnStyles} text-sm text-gray-800 relative pl-3 flex items-center hover:text-gray-700 transition-colors duration-200`}
          onClick={onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="11"
            viewBox="0 0 7 11"
            className="absolute left-0 transitions-all duration-200"
          >
            <path
              fill="currentColor"
              d="M4.83103527,9.84329415 L0.331035268,5.79329415 L0.243010215,5.70358932 L0.202522837,5.65365447 L0.202522837,5.65365447 L0.14518054,5.56943293 L0.14518054,5.56943293 L0.0908476069,5.46707519 L0.0908476069,5.46707519 L0.059988647,5.39172189 L0.059988647,5.39172189 L0.029310721,5.2911587 L0.00768544136,5.17444862 L0.00768544136,5.17444862 L1.02504405e-05,5.04351795 C0.00049249398,4.96474884 0.0102283591,4.88593508 0.029310721,4.8088413 L0.0618484275,4.70319152 L0.0618484275,4.70319152 L0.110040712,4.59336795 L0.110040712,4.59336795 L0.190364565,4.46292884 L0.190364565,4.46292884 L0.256705854,4.38103527 L0.256705854,4.38103527 L0.331035268,4.30670585 L4.83103527,0.256705854 C5.24154529,-0.112753166 5.87383513,-0.0794747532 6.24329415,0.331035268 C6.58433324,0.709967596 6.58221145,1.27787207 6.25698979,1.65358932 L6.16896473,1.74329415 L2.49401025,5.0499914 L6.16896473,8.35670585 C6.57947475,8.72616487 6.61275317,9.35845471 6.24329415,9.76896473 C5.90225505,10.1478971 5.33726544,10.2054013 4.92948469,9.92141634 L4.83103527,9.84329415 Z"
            />
          </svg>
          Previous Question
        </button>
      )}
    </div>
  );
};

PreviousQuestion.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PreviousQuestion;
