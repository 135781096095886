import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import farmersInsurance from '@/assets/img/farmers-insurance.png';
import esurance from '@/assets/img/esurance.png';
import nationwide from '@/assets/img/nationwide.png';
import progressive from '@/assets/img/progressive.png';
import usaa from '@/assets/img/usaa.png';
import Rate from './components/Rate';

import { css } from '@emotion/css';

const YourQuotes = () => {
  const { info } = useSelector((state) => state.form);

  const [waitMessage, setWaitMessage] = useState('Retrieving the best rates..');
  const [showRates, setShowRates] = useState(false);
  const [rates, setRates] = useState([
    {
      label: 'Nationwide',
      logo: nationwide,
      url: 'https://www.nationwide.com',
      recommended: true,
    },
    {
      label: 'Farmer Insurance',
      logo: farmersInsurance,
      url: 'https://www.farmers.com',
      recommended: true,
    },
    {
      label: 'Progressive',
      logo: progressive,
      url: 'https://www.progressive.com',
      recommended: false,
    },
    {
      label: 'usaa',
      logo: usaa,
      url: 'https://www.usaa.com',
      recommended: false,
    },

    {
      label: 'esurance',
      logo: esurance,
      url: 'https://www.esurance.com/',
      recommended: false,
    },
  ]);

  const quoteStyle = css`
    .rate {
      @media screen and (min-width: 768px) {
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        margin-left: inherit;
        margin-right: inherit;
      }
      margin-bottom: -1px;
      margin-right: -1px;
      margin-left: -1px;
    }
  `;

  const fetchRates = () => {
    setTimeout(() => {
      setWaitMessage('Hang tight..');
    }, 2800);
    setTimeout(() => {
      setShowRates(true);
    }, 4000);
  };

  useEffect(() => {
    fetchRates();
  }, []);

  return showRates ? (
    <section className="max-w-5xl mx-auto">
      <header className=" p-5 md:p-8">
        <div className="text-sm text-gray-800">3 match found</div>
        <div className="text-xl ">
          {info.firstname}, Check your rates for potential savings!
        </div>
      </header>
      <section className={`${quoteStyle} mb-12 md:p-8 md:pt-0`}>
        {rates.map((rate, i) => (
          <Rate rate={rate} key={`rt-${i}`} />
        ))}
      </section>
    </section>
  ) : (
    <div className="py-12 md:py-32 flex flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 105 105"
        fill="#2E3192"
      >
        <circle cx="12.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
          <animate
            attributeName="fill-opacity"
            begin="100ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="52.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="300ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="52.5" cy="52.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="600ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="92.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="800ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="92.5" cy="52.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="400ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="12.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="700ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="52.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="500ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="92.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="200ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <div className="mt-4 sm:mt-6 sm:text-lg font-medium">{waitMessage}</div>
    </div>
  );
};

export default YourQuotes;
