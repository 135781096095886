import { createSlice, current } from '@reduxjs/toolkit';
import { years, otherYears } from '@/data/years';
import { makes, otherMakes } from '@/data/make';
import model from '@/data/model';
import { getNextQuestion, getPreviousQuestion } from '@/utils/helpers';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    currentQuestion: 1,
    total: 18,
    questions: {
      q1: {
        label: 'Enter Zip Code to begin',
        formType: 'zip-code',
        name: 'zip code',
        type: 'zip-code',
        autoNext: false,
        name: 'zip-code',
        btnLabel: 'Check Rates',
        validate: {
          required: true,
          length: 5,
        },
      },
      q2: {
        label: 'Select Your Vehicle Year',
        type: 'vehicle_year',
        formType: 'select',
        autoNext: true,
        options: years,
        otherOptions: otherYears,
        otherOptionsLabel: 'Previous Years',
      },
      q3: {
        label: 'Select Your Vehicle Make',
        type: 'vehicle_make',
        formType: 'select',
        autoNext: true,
        options: makes,
        otherOptions: otherMakes,
        otherOptionsLabel: 'Other Makes',
      },
      q4: {
        label: 'Select Your Vehicle Model',
        type: 'vehicle_model',
        formType: 'select',
        autoNext: true,
        options: [],
      },
      q5: {
        label: 'Add Second Vehicle? (Save Additional 20%)',
        type: 'vehicle_add',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q6: {
        label: 'Select Second Vehicle Year',
        type: 'vehicle_year',
        formType: 'select',
        autoNext: true,
        options: years,
        skipIf: { 5: 'No' },
      },
      q7: {
        label: 'Select Second Vehicle Make',
        type: 'vehicle_make',
        formType: 'select',
        autoNext: true,
        options: makes,
        otherOptions: otherMakes,
        otherOptionsLabel: 'Other Makes',
        skipIf: { 5: 'No' },
      },
      q8: {
        label: 'Select Second Vehicle Model',
        type: 'vehicle_model',
        formType: 'select',
        autoNext: true,
        options: [],
        skipIf: { 5: 'No' },
      },
      q9: {
        label: 'Have you had auto insurance in the past 30 days?',
        type: 'insurance_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q10: {
        label: 'Gender',
        type: 'gender',
        formType: 'select',
        autoNext: true,
        options: ['Male', 'Female', 'Non-Binary'],
      },
      q11: {
        label: 'Are you currently married?',
        type: 'married_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q12: {
        label: 'Homeowner?',
        type: 'homeowner_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q13: {
        label:
          'Would you like to also receive renters insurance policy quotes? You may be able to bundle and save even more on your auto policy.',
        type: 'renters_quotes_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },

      q14: {
        label: 'Has anyone on this policy had:',
        type: 'accident_yn',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        options: [
          {
            id: 1,
            label:
              'An at-fault accident in the past <strong>three (3) years</strong>?',
            options: ['Yes', 'No'],
            formType: 'select',
            validate: {
              required: true,
            },
          },
          {
            id: 2,
            label:
              '<strong>Two (2) or more</strong> tickets in the past <strong>three (3) years</strong>?',
            options: ['Yes', 'No'],
            formType: 'select',
            validate: {
              required: true,
            },
          },
          {
            id: 3,
            label:
              'A DUI conviction in the past <strong>three (3) years</strong>?',
            options: ['Yes', 'No'],
            formType: 'select',
            validate: {
              required: true,
            },
          },
        ],
      },
      q15: {
        label:
          'Are either you or your spouse an active member, or an honorably discharged veteran of the US military?',
        type: 'military_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q16: {
        label: 'Birthdate',
        type: 'birthday',
        formType: 'birthday',
        autoNext: false,
        name: 'birthday',
        placeholder: 'yyyy/mm/dd',
        btnLabel: 'Continue',
        validate: {
          required: true,
          regex: 'birthdate',
        },
      },
      q17: {
        type: 'info_yn',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        options: [
          {
            id: 1,
            formType: 'text',
            name: 'first_name',
            itemLabel: 'First Name',
            validate: {
              required: true,
            },
          },
          {
            id: 2,
            formType: 'text',

            name: 'last_name',
            itemLabel: 'Last Name',
            validate: {
              required: true,
            },
          },
          {
            id: 3,
            formType: 'email',
            name: 'email',
            itemLabel: 'Email Address',
            validate: {
              required: true,
              regex: 'email',
            },
          },
        ],
      },
      q18: {
        label: 'Last Step!',
        type: 'address_el',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        options: [
          {
            id: 11,
            formType: 'text',
            name: 'address',
            itemLabel: 'Address',
            validate: {
              required: true,
            },
          },
          {
            id: 22,
            formType: 'phone',
            name: 'phone',
            itemLabel: 'Phone Number',
            validate: {
              required: true,
              regex: 'phone',
            },
          },
        ],
      },
    },

    info: {
      firstname: '',
    },

    answers: {},

    errors: {},
  },
  reducers: {
    addError: (state, { payload }) => {
      const { qId, subId, error } = payload;
      state.errors[qId] = state.errors[qId] || {};

      if (subId) {
        state.errors[qId][subId] = error;
        return;
      }

      state.errors[qId] = error;
    },

    removeError: (state, { payload }) => {
      const { qId, subId } = payload;
      if (subId) {
        delete state.errors[qId][subId];
        return;
      }
      delete state.errors[qId];
    },

    setAnswer: (state, { payload }) => {
      const { qId, answer } = payload;
      state.answers[qId] = answer;

      const currentQuestion = state.questions[`q${qId}`];

      // Temporary Set Vehicle Model
      if (
        currentQuestion.type === 'vehicle_make' &&
        model[answer] &&
        state.questions[`q${qId + 1}`]
      ) {
        state.questions[`q${qId + 1}`].options = model[answer];
      }

      if (currentQuestion.type === 'info_yn') {
        const firstname = state.answers[qId][1];
        if (firstname) {
          state.info.firstname = firstname;
        }
      }
    },

    goToNextQuestion: (state, { payload }) => {
      const next = getNextQuestion(
        payload,
        state.questions,
        state.answers,
        state.total
      );

      state.currentQuestion = state.currentQuestion + next;
    },

    goToPreviousQuestion: (state) => {
      const previous = getPreviousQuestion(
        state.currentQuestion,
        state.questions,
        state.answers
      );

      state.currentQuestion = state.currentQuestion - previous;
    },
  },
});

export const {
  setAnswer,
  addError,
  removeError,
  goToNextQuestion,
  goToPreviousQuestion,
} = formSlice.actions;

export default formSlice.reducer;
